@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  b {
    @apply font-bold
  }

  h1 {
    @apply block text-2xl font-extrabold;
  }

  h2 {
    @apply block text-xl font-extrabold;
  }

  h3 {
    @apply block text-lg font-extrabold;
  }

  h4 {
    @apply block text-base font-extrabold;
  }

  h5 {
    @apply block text-sm font-extrabold;
  }

  h6 {
    @apply block text-xs font-extrabold;
  }

  i {
    @apply italic
  }

  p {
    @apply block my-4 mx-0;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Nosifer';
    src: url('assets/fonts/Nosifer/Nosifer-Regular.ttf') format('truetype');
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}